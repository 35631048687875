.appdownloadview-content {
	--background: 'none';
	background-image: url(../../ui/assets/img/desktop_downloadapp_header.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position-y: top;
	background-color: #f5f5f5;
	color: #263238;
}

.appdownloadview-footerimage {
	--background: 'none';
	background-image: url(../../ui/assets/img/desktop_downloadapp_footer.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position-y: bottom;
	height: 200px;
	top: auto !important;
	right: auto !important;
	bottom: 0px !important;
	left: 0px !important;
	position: fixed !important;
	width: 100%;
	z-index: 1;
}

.appdownloadview-footertext {
	position: relative;
	z-index: 5;
}

.appdownloadview-maincontentdiv {
	margin-top: 30%;
	text-align: center;
	z-index: 5;
}

.appdownloadview-maincard {
	background-color: #e8ecfa;
	color: #263238;
	border: none;
	box-shadow: none;
	overflow: visible;
	margin-top: 70px;
	z-index: 5;
}

.appdownloadview-contentgrid {
	height: 100%;
}

.appdownloadview-contentrow {
	height: 100%;
	flex-direction: column;
	color: #263238;
}

.appdownloadview-applogobox {
	background-image: url(../../ui/assets/img/MyLEADS_logo_icon.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: inline-block;
	height: 110px;
	width: 110px;
	margin-top: -40px;
	border: #eeeeee solid 1px;
	border-radius: 5px;
}

.appdownloadview-subtext {
	display: block;
}

.appdownloadview-appstorebuttons {
	display: inline-block;
	height: 40%;
	width: 45%;
	text-align: center;
}
.appdownloadview-applestorebtn {
	background-image: url(../../ui/assets/img/Download_App_Store_Badge.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	display: inline-block;
	height: 70px;
	width: 200px;
	margin: 10px;
}

.appdownloadview-googlestorebtn {
	background-image: url(../../ui/assets/img/Download_Google_Play_Store_Badge.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	display: inline-block;
	height: 70px;
	width: 200px;
	margin: 10px;
}

@media only screen and (max-width: 640px) {
	.appdownloadview-appstorebuttons {
		width: 95%;
	}
	.appdownloadview-applestorebtn,
	.appdownloadview-googlestorebtn {
		max-width: 200px;
		margin: 3px;
	}
}

@media only screen and (max-width: 800px) {
	.appdownloadview-appstorebuttons {
		width: 95%;
	}
	.appdownloadview-applestorebtn,
	.appdownloadview-googlestorebtn {
		max-width: 200px;
		width: 90%;
	}
}

@media only screen and (max-width: 1024px) {
	/* Styles */
}

@media only screen and (max-width: 1280px) {
	/* Styles */
}
