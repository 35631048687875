/* Ionic Variables and Theming. For more information, please see
// https://beta.ionicframework.com/docs/theming/
// The app direction is used to include
// rtl styles in your app. For more information, please see
// https://beta.ionicframework.com/docs/layout/rtl
// $app-direction: ltr;
// Ionic Colors
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic provides eight layered colors
// that can be changed to theme an app. Additional colors can be
// added as well (see below). For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
// To easily create custom color palettes for your app’s UI,
// check out our color generator:
// https://beta.ionicframework.com/docs/theming/color-generator
*/

:root {
	--ion-color-angular: #ac282b;
	--ion-color-communication: #8e8d93;
	--ion-color-tooling: #fe4c52;
	--ion-color-services: #fd8b2d;
	--ion-color-design: #fed035;
	--ion-color-workshop: #69bb7b;
	--ion-color-food: #3bc7c4;
	--ion-color-documentation: #b16be3;
	--ion-color-navigation: #6600cc;

	--ion-color-primary: #3880ff;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;

	--ion-color-secondary: #0cd1e8;
	--ion-color-secondary-rgb: 12, 209, 232;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #0bb8cc;
	--ion-color-secondary-tint: #24d6ea;

	--ion-color-tertiary: #7044ff;
	--ion-color-tertiary-rgb: 112, 68, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #633ce0;
	--ion-color-tertiary-tint: #7e57ff;

	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16, 220, 96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;

	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255, 206, 0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255, 255, 255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;

	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245, 61, 61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 34, 34;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 244, 244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	--ion-color-conexsys-blue: #1e22ca;
	--ion-color-conexsys-blue-rgb: 30, 34, 202;
	--ion-color-conexsys-blue-contrast: #ffffff;
	--ion-color-conexsys-blue-contrast-rgb: 255, 255, 255;
	--ion-color-conexsys-blue-shade: #1a1eb2;
	--ion-color-conexsys-blue-tint: #3538cf;
}

/* Additional Ionic Colors
// --------------------------------------------------
// In order to add colors to be used with Ionic components,
// the color should be added as a class with the convention `.ion-color-{COLOR}`
// where `{COLOR}` is the color to be used on the Ionic component
// and each variant is defined for the color. For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
*/

.ion-color-favorite {
	--ion-color-base: #69bb7b;
	--ion-color-base-rgb: 105, 187, 123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-twitter {
	--ion-color-base: #1da1f4;
	--ion-color-base-rgb: 29, 161, 244;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #1a8ed7;
	--ion-color-tint: #34aaf5;
}

.ion-color-google {
	--ion-color-base: #dc4a38;
	--ion-color-base-rgb: 220, 74, 56;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #c24131;
	--ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
	--ion-color-base: #23b6ea;
	--ion-color-base-rgb: 35, 182, 234;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #1fa0ce;
	--ion-color-tint: #39bdec;
}

.ion-color-facebook {
	--ion-color-base: #3b5998;
	--ion-color-base-rgb: 59, 89, 152;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #344e86;
	--ion-color-tint: #4f6aa2;
}

.ion-color-conexsys-blue {
	--ion-color-base: var(--ion-color-conexsys-blue);
	--ion-color-base-rgb: var(--ion-color-conexsys-blue-rgb);
	--ion-color-contrast: var(--ion-color-conexsys-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-conexsys-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-conexsys-blue-shade);
	--ion-color-tint: var(--ion-color-conexsys-blue-tint);
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

:root {
	--ion-headings-font-weight: 300;
	--ion-color-angular: #ac282b;
	--ion-color-communication: #8e8d93;
	--ion-color-tooling: #fe4c52;
	--ion-color-services: #fd8b2d;
	--ion-color-design: #fed035;
	--ion-color-workshop: #69bb7b;
	--ion-color-food: #3bc7c4;
	--ion-color-documentation: #b16be3;
	--ion-color-navigation: #6600cc;
}

/*
 * Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme {
	--ion-color-primary: #428cff;
	--ion-color-primary-rgb: 66, 140, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3a7be0;
	--ion-color-primary-tint: #5598ff;

	--ion-color-secondary: #50c8ff;
	--ion-color-secondary-rgb: 80, 200, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #46b0e0;
	--ion-color-secondary-tint: #62ceff;

	--ion-color-tertiary: #6a64ff;
	--ion-color-tertiary-rgb: 106, 100, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #5d58e0;
	--ion-color-tertiary-tint: #7974ff;

	--ion-color-success: #2fdf75;
	--ion-color-success-rgb: 47, 223, 117;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #29c467;
	--ion-color-success-tint: #44e283;

	--ion-color-warning: #ffd534;
	--ion-color-warning-rgb: 255, 213, 52;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0bb2e;
	--ion-color-warning-tint: #ffd948;

	--ion-color-danger: #ff4961;
	--ion-color-danger-rgb: 255, 73, 97;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #e04055;
	--ion-color-danger-tint: #ff5b71;

	--ion-color-dark: #f4f5f8;
	--ion-color-dark-rgb: 244, 245, 248;
	--ion-color-dark-contrast: #000000;
	--ion-color-dark-contrast-rgb: 0, 0, 0;
	--ion-color-dark-shade: #d7d8da;
	--ion-color-dark-tint: #f5f6f9;

	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0, 0, 0;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	--ion-color-light: #222428;
	--ion-color-light-rgb: 34, 36, 40;
	--ion-color-light-contrast: #ffffff;
	--ion-color-light-contrast-rgb: 255, 255, 255;
	--ion-color-light-shade: #1e2023;
	--ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.ios {
	--ion-background-color: #000000;
	--ion-background-color-rgb: 0, 0, 0;

	--ion-text-color: #ffffff;
	--ion-text-color-rgb: 255, 255, 255;

	--ion-color-step-50: #0d0d0d;
	--ion-color-step-100: #1a1a1a;
	--ion-color-step-150: #262626;
	--ion-color-step-200: #333333;
	--ion-color-step-250: #404040;
	--ion-color-step-300: #4d4d4d;
	--ion-color-step-350: #595959;
	--ion-color-step-400: #666666;
	--ion-color-step-450: #737373;
	--ion-color-step-500: #808080;
	--ion-color-step-550: #8c8c8c;
	--ion-color-step-600: #999999;
	--ion-color-step-650: #a6a6a6;
	--ion-color-step-700: #b3b3b3;
	--ion-color-step-750: #bfbfbf;
	--ion-color-step-800: #cccccc;
	--ion-color-step-850: #d9d9d9;
	--ion-color-step-900: #e6e6e6;
	--ion-color-step-950: #f2f2f2;

	--ion-toolbar-background: #0d0d0d;

	--ion-item-background: #1c1c1c;
	--ion-item-background-activated: #313131;
}

/*
 * Material Design Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.md {
	--ion-background-color: #121212;
	--ion-background-color-rgb: 18, 18, 18;

	--ion-text-color: #ffffff;
	--ion-text-color-rgb: 255, 255, 255;

	--ion-border-color: #222222;

	--ion-color-step-50: #1e1e1e;
	--ion-color-step-100: #2a2a2a;
	--ion-color-step-150: #363636;
	--ion-color-step-200: #414141;
	--ion-color-step-250: #4d4d4d;
	--ion-color-step-300: #595959;
	--ion-color-step-350: #656565;
	--ion-color-step-400: #717171;
	--ion-color-step-450: #7d7d7d;
	--ion-color-step-500: #898989;
	--ion-color-step-550: #949494;
	--ion-color-step-600: #a0a0a0;
	--ion-color-step-650: #acacac;
	--ion-color-step-700: #b8b8b8;
	--ion-color-step-750: #c4c4c4;
	--ion-color-step-800: #d0d0d0;
	--ion-color-step-850: #dbdbdb;
	--ion-color-step-900: #e7e7e7;
	--ion-color-step-950: #f3f3f3;

	--ion-item-background: #1e1e1e;

	--ion-toolbar-background: #1f1f1f;

	--ion-tab-bar-background: #1f1f1f;
}

/*
 * CUSTOM
 * ----------------------------------------------------------------------------
 */

ion-content {
	--background: #f2f6fc;
}
.dark-theme ion-content {
	--background: #000000;
}

ion-menu {
	--background: #fff;
}
ion-menu ion-content {
	--background: #fff;
}
.dark-theme ion-menu {
	--background: #1e1e1e;
}
.dark-theme ion-menu ion-content {
	--background: #1e1e1e;
}
