.menu-toolbar {
	min-height: 50px !important;
}
.menu--collapsed {
	--side-max-width: 65px !important;
	--side-min-width: 65px !important;
}
.menu--collapsed ion-item {
	min-width: 275px;
}
.menu-main {
	--side-max-width: 275px;
}

.menu-toolbar-title {
	text-align: center;
}

.menu-toolbar-title-logo-img {
	padding: 5px 0;
	height: 50px;
}

.menu-footer-img {
	padding-bottom: 10px;
	cursor: pointer;
	width: 50%;
}

.menu-content-list {
	padding-top: 0px;
}

.menu-footer {
	text-align: center;
}

.menu-footer p {
	font-size: 11pt;
}
