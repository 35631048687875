/* Global card settings */
.conexsys-card ion-card-title {
	font-size: 16px;
}

.conexsys-card p {
	padding-bottom: 15px;
}
/* Customized cards */
.info-card {
	background-image: url('../assets/img/bg-waves.svg');
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: 100% auto;
	background-color: var(--background);
	padding: 15px;
}

.info-card h1 {
	color: var(--ion-color-primary);
	padding-bottom: 15px;
}

.info-card h2 {
	padding-bottom: 50px;
}

.info-card ion-button {
	margin: 20px 0;
}

.event-card ion-list-header {
	padding-left: 0;
}

.sort-lead-divider {
	background: transparent;
}

@media only screen and (max-width: 768px) {
	.info-card ion-card-header {
		padding: 0 20px;
	}

	.info-card {
		padding: 0;
	}

	.info-card h1 {
		font-size: 16px;
		padding-bottom: 5px;
	}

	.info-card h2 {
		font-size: 12px;
		padding-bottom: 20px;
	}

	.info-card p {
		font-size: 12px;
	}

	.info-card ion-button {
		font-size: 12px;
	}

	.event-card h1 {
		font-size: 16px;
	}

	.event-card ion-label {
		font-size: 14px !important;
	}
}
