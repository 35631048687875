.ion-card-order {
	padding-top: 30px;
}

h1 {
	color: var(--ion-color-dark);
	font-size: 22px;
	font-weight: 500;
}

@media only screen and (max-width: 768px) {
	h1 {
		font-size: 20px;
	}
}
