body {
	font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 700;
}
header.masthead {
	position: relative;
	background-color: #343a40;
	background: url(../../ui/assets/img/splashbackground.jpg) no-repeat center
		center;
	background-size: cover;
	padding-top: 8rem;
	padding-bottom: 8rem;
}
header.masthead .overlay {
	position: absolute;
	background-color: #212529;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	opacity: 0.3;
}
header.masthead h1 {
	font-size: 2rem;
}
@media (min-width: 768px) {
	header.masthead {
		padding-top: 12rem;
		padding-bottom: 12rem;
	}
	header.masthead h1 {
		font-size: 3rem;
	}
}
.showcase .showcase-text {
	padding: 3rem;
}
.showcase .showcase-img {
	min-height: 30rem;
	background-size: cover;
}
@media (min-width: 768px) {
	.showcase .showcase-text {
		padding: 7rem;
	}
}
.showcase .showcase-img-1 {
	background-image: url('../../ui/assets/img/pexels-photo-1181396.jpeg');
}
.showcase .showcase-img-2 {
	background-image: url('../../ui/assets/img/pexels-photo-669610.jpeg');
}
.showcase .showcase-img-3 {
	background-image: url('../../ui/assets/img/adult-architect-blueprint-business-416405.jpg');
}
.features-icons {
	padding-top: 7rem;
	padding-bottom: 7rem;
}
.features-icons .features-icons-item {
	max-width: 20rem;
}
.features-icons .features-icons-item .features-icons-icon {
	height: 7rem;
}
.features-icons .features-icons-item .features-icons-icon i {
	font-size: 4.5rem;
}
.features-icons .features-icons-item:hover .features-icons-icon i {
	font-size: 5rem;
}
.testimonials {
	padding-top: 7rem;
	padding-bottom: 7rem;
}
.testimonials .testimonial-item {
	max-width: 18rem;
}
.testimonials .testimonial-item .testimonial-item-1 {
	max-width: 12rem;
	box-shadow: 0 5px 5px 0 #adb5bd;
	background-image: url('../../ui/assets/img/Opticon_9725.jpg');
	height: 140px;
	width: 140px;
	margin-left: 80px;
	background-position-x: 200px;
	background-position-y: -20px;
}
.testimonials .testimonial-item .testimonial-item-2 {
	max-width: 12rem;
	box-shadow: 0 5px 5px 0 #adb5bd;
	background-image: url('../../ui/assets/img/bc500dc.jpg');
	height: 140px;
	width: 140px;
	margin-left: 80px;
	background-position-x: 200px;
	background-position-y: -20px;
}
.testimonials .testimonial-item .testimonial-item-3 {
	max-width: 12rem;
	box-shadow: 0 5px 5px 0 #adb5bd;
	background-image: url('../../ui/assets/img/icon_XHDPI.png');
	height: 140px;
	width: 140px;
	margin-left: 80px;
	background-repeat: no-repeat;
	background-position-x: 20px;
	background-position-y: 20px;
}

.call-to-action {
	position: relative;
	background-color: #343a40;
	/*background: url(../img/bg-masthead.jpg) no-repeat center center;*/
	background-size: cover;
	padding-top: 7rem;
	padding-bottom: 7rem;
}
.call-to-action .overlay {
	position: absolute;
	background-color: #212529;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	opacity: 0.3;
}
footer.footer {
	padding-top: 4rem;
	padding-bottom: 4rem;
}
